.container {
    gap: 1rem;
    max-width: 80%;
    margin: auto;
}

.heading {
    display: grid;
    grid-row-gap: 1rem;
    color: #F26E5F;
}