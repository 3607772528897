.container {
    display: grid;
    grid-gap: 1rem;
    max-width: 80%;
    margin: auto;
    padding: 1em 0;

    img {
        width: auto;
        max-height: 20vh;
        border-radius: 8px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    }
}

.heading {
    color: #F26E5F;
}

.form {
    display: grid;
    grid-gap: 1.2rem;
    // width: 500px;
    // height: max-content;

    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;

        .switch {
            display: flex;
            grid-column: span 2;
            gap: 0.5em;
        }
    }
}

.field {
    background-color: #ffffff;
}