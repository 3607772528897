.container {
  width: 340px;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.trayHeader {
  display: flex;
  flex-direction: row;
  /* justify-content: stretch; */
}

.activeTab {
  cursor: pointer;
  background-color: orangered;
}

.inactiveTab {
  cursor: pointer;
  background-color: #f1f1f1;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 10em;
  overflow: auto;
  height: 2em;
}

.tray::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: white;
  border-radius: 80px;
}

.tray::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 70px;
}

.tray {
  min-height: 15.625rem;
  max-height: 15.625rem;
  overflow: scroll;
}
