.user-cta-component {
    position: absolute;
    right: 0;
    display: grid;
    grid-template-columns: 90% 10%;
    width: 20rem;
    margin-right: 30px;
}

@media (max-width: 768px) { /* Tablet */
    .user-cta-component {
        width: 15rem;
        margin-right: 15px;
        grid-template-columns: 80% 20%;
    }
}

@media (max-width: 480px) { /* Mobile */
    .user-cta-component {
        position: absolute;
        width: 5.5rem;
        margin: 0 0.1rem;
        grid-template-columns: 1fr; /* Single column for smaller screens */
    }
}
.login-component{

}

.login-form-model-container {
    height: 45rem;
    width: 45rem;
    background-color: white;
}

.login-form-model {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}