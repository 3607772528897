.node {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px;
}

.nodeText {
  font-size: 18px;
  text-transform: capitalize;
  color: black;
  font-weight: 400;
  margin-left: 30px;
}
