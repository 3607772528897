@import '../../../../../styles/colors';

#campaignFilters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  margin: 2rem 1rem;

  .searchFormControl {
    grid-column: span 2;
    flex: 1;
    fieldset {
      top: 0;
    }
    & > *:first-child {
      border-radius: 0.5rem;
    }
    svg {
      margin-right: 0.4rem;
    }
    input {
      padding-block: 0.95rem;
    }
  }

  .formControl {
    label {
      color: $normal-text !important;
    }
    &:focus-within {
      label {
        color: $primary-color !important;
      }
    }
  }
}

#campaignDownloads {
  display: grid;
  grid-template-columns: 57% 37%;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 1rem 1.5rem;
  gap: 1rem 1.5rem;
  background: #fcf3f2;
  padding: 1rem;
  border: 2px solid #E99C90;
  border-radius: 10px;
  margin: 2rem 1rem;

  .downloadPromptText {
    font-weight: bold;
    margin: auto 0rem auto 1.5rem;
  }
  .campaignDownloadsCTA {
    grid-template-columns: 45% 45%;
    display: grid;
    justify-content: space-around;

    .outlinedViewButton {
      background-color: white;
      color: #f2755f;
      border-color: #f2755f;
    }

  }

}