.container {
    text-align: left;
    width: 100%;

    label {
        margin-bottom: 4px;
        display: block;
    }

    input {
        background-color: white;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        box-shadow: 0px 4px 5px rgba(210, 210, 210, 0.25);
        border-bottom: #F26E5F 1px solid;
        height: 6vh;
        padding: 0 0.4rem;
        font-size: 14px;
    }
}