.campaignReportHeader {
  .campaignReportTitle {
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .campaignReportInfo {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-top: 2.5rem;
    justify-content: space-between;
    .campaignTitle {
      font-weight: bold;
      text-transform: capitalize;
    }
    .campaignROIContainer {
      display: flex;
    }
    .roiField {
      padding: 0rem 1rem;
    }
    .roiButton {
      width: 100%;
    }
  }

  .campaignReportAlert {
    width: fit-content;
    margin-inline: auto;

    .campaignReportAlertTitle {
      font-size: 1.25rem;
      font-weight: 600;
      margin-top: -4px;
    }
    div {
      align-items: flex-start;
    }
  }

  margin-bottom: 1.5rem;
}
