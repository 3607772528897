@import '../../../../../styles/colors';

#campaignTableContainer {
  border-radius: 0.5rem;

  .campaignTableHeader {
    tr th:first-child {
      text-align: left !important;
      padding-left: 1.5rem;
    }
  }

  a:hover {
    color: $primary-color;
  }

  .campaignTable {
    td {
      font-size: 1rem;
      font-weight: 600;
    }

    td:first-child,
    th:first-child {
      padding-left: 1rem;
    }
  }
}
