.selectedStepLabel {
  text-align: center;
  font: 14px/19px Nunito;
  font-weight: 600;
  letter-spacing: 0;
  color: #404040;
  opacity: 1;
}

.stepLabel {
  text-align: center;
  font: 13px/18px Nunito;
  letter-spacing: 0;
  color: #a8a8a8;
  opacity: 1;
}

.completed-dot {
  z-index: 5;
  margin-top: 2px;
  height: 15px;
  width: 15px;
  background-color: #faccc3;
  border: 2px solid #f26155;
  border-radius: 50%;
  display: inline-block;
}

.dot {
  margin-top: 4px;
  height: 15px;
  width: 15px;
  background-color: #faccc3;
  border-radius: 50%;
  display: inline-block;
}
