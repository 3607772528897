@import '../../../../../../styles/colors';

$gap: 1rem;

#campaignDetailContainer {
  padding: 1rem;
  background: hsla(0, 0%, 67%, 0.12);

  .campaignTableRowCards {
    display: flex;
    gap: $gap;
    margin-bottom: $gap;
  }

  .campaignDetail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: $gap;
    padding-right: $gap;
    flex: 1;
    border-right: 1px solid $border-color;

    .link {
      grid-column: 1 / -1;
      font-weight: 600;
    }

    .link:hover {
      cursor: pointer;
    }
  }
  .surveyDetailContainer{
    width: 50%;
    .surveyDetailHeader{
      font-weight: 800 !important;
      color: $primary-color
    }
  }
  .campaignDetailIcon {
    align-self: center;
    .doubleArrowIcon {
      transform: rotate(90deg);
    }
  }
  .calculateRoiForm {
    max-width: 200px;
  }

  .ctaContainer {
    display: flex;
    flex-direction: row-reverse;
  }

  .reportGenerateBtn {
    display: block;
    margin: 0rem 1rem;
  }
}

.progressContainer {
  display: block;
  margin-top: .75rem;
  width: 100%;
  height: 1rem;
  border-radius: 0.25rem;
  background-color: rgb(230, 230, 230);
  position: relative;
  overflow: hidden;

  .progress {
    display: block;
    height: 100%;
    background-color: rgb(43, 194, 83);
    background-image: linear-gradient(
      center bottom,
      rgb(43, 194, 83) 37%,
      rgb(84, 240, 84) 69%
    );
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
      overflow: hidden;
    }
  }

  .count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-size: 0.5rem;
  }
}


@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}