@import '../../../../styles/colors';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    margin-bottom: 2rem;
    box-shadow: 0px 4px 5px rgba(210, 210, 210, 0.25);

    .btns {
        display: flex;
        gap: 1rem;
    }

    .formControl {
        label {
            color: $normal-text !important;
        }

        &:focus-within {
            label {
                color: $primary-color !important;
            }
        }
    }

}