.root {
  height: 100vh;
}

.react-tabs {
  display: flex;
  //   margin: 30px 0;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 280px;
  margin: 0;
  padding: 0;
  height: 74.7vh !important;
  padding-top: 50px;
}

.react-tabs__tab {
  list-style: none;
  padding: 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab--selected {
  border-color: white;
  border-left: 10px solid #f26e5f !important;
}
.react-tabs__tab--selected h6 {
  color: #f26e5f !important;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 40px;
  outline: none;
}

.react-tabs__tab > .MuiTypography-h2 {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #333333;
}

.react-tabs__tab--selected {
  padding-left: 30px;
}

.panel-content {
  margin-left: 40px;
}

.listParagaraph {
  font-size: 15px !important;
  color: #333333 !important;
}

.listTitle {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #333333;
}
.searchIcon {
  width: 97%;
  border: 1px solid #d0d7de;
  padding: 5px;
}

.react-tabs__tab-panel {
  width: 82%;
  padding: 30px;
  padding-bottom: 0;
  // box-shadow: -3px 10px 5px -2px;
  box-shadow: -9px 0px 4px -5px #eaeef2;
}

.searchIconStyle {
  color: #95a2af;
}
.configure {
  display: flex;
  justify-content: space-between;
  width: 97%;
}

.productGuide h4 {
  padding-top: 15px;
  font-weight: 700;
  font-size: 30px;
}

.gridClass {
  display: flex;
  align-items: flex-start;
}

.options {
  margin-left: 12px;
  width: 206px;
}

.optionTitle {
  font-weight: 700;
  font-size: 18;
  margin-bottom: 0;
  cursor: pointer;
}
.disabledColor {
  display: flex;
  align-items: flex-start;
  color: #c6c6c6;
  text-decoration: none;
}
.disabledColor:hover {
  color: #c6c6c6;
  text-decoration: none;
}
img.disabled {
  opacity: 0.7;
  color: #c6c6c6;
  // background-color: #c6c6c6;
}
.createOfferLink {
  text-decoration: none;
  font-weight: 700;
  font-size: 18;
}
