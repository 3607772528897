.dialog {
	display: flex;
	align-items: center;
	justify-content: center;

	.modal {
		max-height: 800px;
		overflow-y: hidden;
		display: flex;
		flex-direction: column;
		padding: 2rem;
		gap: 1rem;

		.close {
			align-self: flex-end;
			padding: 0.3rem 3rem;
		}

		.variantList {
			display: flex;
			flex-direction: column;
			flex: 1 1;
			max-height: 600px;
			overflow-y: auto;
		}
	
		.divider {
			background-color: #E9E9E9;
			border-width: 2px;
		}
	}
}
