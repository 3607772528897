.container {
  text-align: left;
  width: fit-content;
  padding-bottom: 0.8rem;

  .containerDefault {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
  }

  input {
    background-color: rgba(252, 252, 252, 0.476);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: 0px 4px 5px rgba(210, 210, 210, 0.25);
    border-bottom: #F26E5F 1px solid;
    height: 6vh;
    padding: 0 0.4rem;
    font-size: 1.8rem;
    color: #F26E5F;
  }
}