.container {
    gap: 1rem;
    max-width: 80%;
    margin: auto;
}

.heading {
    color: #F26E5F;
}

.form {
    display: grid;
    gap: 0.5em;

    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;

        .switch {
            display: flex;
            grid-column: span 2;
            gap: 0.5em;
        }
    }

}