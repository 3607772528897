.container {
    flex: 1;
    border-radius: 8px;
    background: #F8F8F8;
    padding: 1.5rem;
    height: 100%;
    overflow: scroll;
    box-shadow: 0px 4px 5px rgba(210, 210, 210, 0.25);

    &::-webkit-scrollbar {
        width: 0;
    }

    overflow-y: scroll;
    overflow-x: hidden;
}