.value-rotator-container {
}
.side-panel-crud-action {
  width: 100%;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-between;*/
  /*margin: 14px 0px;*/
}
.side-panel-selection-container {
  width: 100%;
  min-height: 25rem;
}
.side-panel-action-header {
  color: #6b6a6a;
  font-weight: bold;
  font-size: 14px;
  margin: 8px 0px;
}
.side-panel-action-items-header {
  margin: 1rem 0.5rem;
}

.side-panel-action-item {
  margin: 1rem;
  padding: 1rem;
}

.side-panel-action-item-player {
  margin: 0px 10px;
  cursor: pointer;
}
.side-panel-selection-spinner-container {
  display: flex;
  /*grid-template-columns: 50% 50%;*/
}

.side-panel-crud-save-cancel {
  /*width: 70%;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.side-panel-action-tag-btn {
  cursor: pointer;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  font-size: 12px;
  width: 100%;
  text-align: center;
  color: #f2755f;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffffff;
  border: 1px solid #f2755f;
}

.side-panel-action-tag-btn.active {
  color: white;
  background-color: #f2755f;
}

.side-panel-action-tag-btn.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.side-panel-action-btn {
  width: 100%;
  margin: 0rem 0.5rem !important;
  font-size: 12px !important;
}

.side-panel-action-btn.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.side-panel-crop-info-content {
  display: grid;
  grid-template-columns: 30% 30%;
  font-size: 12px;
  color: #9f9e9e;
}

.side-panel-crop-info-container {
  margin: 1rem 0.5rem;
}

.side-panel-crop-info-header {
  font-size: 14px;
  font-weight: bold;
  margin: 0.5rem 0rem;
}

.side-panel-info-container {
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  border-radius: 0.4rem;
}
