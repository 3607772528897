.container {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 5px;
  margin-top: 40px;
  border-radius: 5%;
  width: 100px;
  top: 6%;
  left: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 1;
  border: 2px solid #eaebeb;
}

.container > div {
  cursor: pointer;
  flex: 1;
  background-color: transparent;
  border-radius: 0px 0px 5px 0px;
}

.container div:nth-child(2) {
  border-left: 2px solid #eaebeb;
}
