@import '../../../../../styles/colors';

#campaignReportTableContainer {
  border-radius: 0.5rem;
  .campaignReportTable {

    td,
    th {
      text-align: center;
    }
    & > * {
      border: 0;
    }
  }
}
