.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 40%;
  font-size: 14px;
}

.detailsTable,
.detailsTable tr,
.detailsTable td {
  border-collapse: collapse !important;
  border: 0px !important;
  font-size: 14px;
}

.detailsTable td {
  min-width: 30px;
  width: max-content;
  white-space: nowrap;
  padding: 4px 10px;
}

.key {
  font-weight: 700;
  font-size: 14px;
}

.value {
  font-weight: 400 !important;
  font-size: 14px;
}
