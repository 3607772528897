.firstConatiner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.customFileUpload[type='file'] {
  color: transparent;
  width: 186px;
  left: -8px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.customFileUpload[type='file']::file-selector-button {
  opacity: 0;
  height: 60px;
  left: 85px;
  position: relative;
  border: 2px solid #f26e5f;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #fef3f2;
  transition: 1s;
  z-index: 2;
  cursor: pointer;
}
