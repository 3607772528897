@import '../../../styles/colors';

table thead#tableHeader {
  .tableHeaderRow {
    background: hsla(0, 0%, 67%, 0.12);
    .tableHeaderCell {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 2;
      padding: 1rem;
      color: $header-text;
    }
  }
}
