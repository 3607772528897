@import "../../../../styles/colors";

#tenantFieldForm {
  .formControl {
    label {
      color: $normal-text !important;
    }
    &:focus-within {
      label {
        color: $primary-color !important;
      }
    }
  }
}
