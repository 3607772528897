.statusIcon {
  margin-right: 5px;
}

.backdrop {
  position: absolute;
  opacity: 0.5;
  z-index: 0;
  color: #fff;
}

.viewOfferLink {
  color: #f07663;
  text-decoration: none;
}

.viewOfferLink:hover {
  color: #f07663;
  text-decoration: none;
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
}

.modalContainer {
  background-color: white;
  padding: 20px 25px;
  min-width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modalContent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 50vh;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  gap: 10px;
  padding-right: 5px;
  margin: 10px 0;
}

.item {
  border: 1px solid rgba(0, 0, 0, 0.09);
  padding: 10px 5px;
  text-align: center;
  min-width: 150px;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
