.container {
  padding: 10px 15px 5px 15px;
  margin-left: 15px;
  box-shadow: 2px 2px 2px 2px #e9e9e9;
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nodeLabel {
  padding-top: 5px;
  font-weight: bold;
}

.infoText {
  padding: 35px 20px 35px 20px;
  background-color: white;
  font-weight: bold;
  border: 2.5px dashed #f4c6bc;
}

.infoArrow {
  margin-left: 175px;
}
