.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 4px 2px -2px gray;
  z-index: 100;
}

.headerActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.headerActionsContainer * {
  margin: 0px 7px;
}

.inputContainer {
  margin-left: 32px;
  line-height: 32px;
  font-size: 18px;
  box-shadow: 0px 2px 3px -2px #888;
  padding: 2px 5px;
  width: 300px;
  cursor: text;
  font-weight: bold;
}

.nameInput {
  border: none;
  outline: none;
  background: transparent;
  line-height: inherit;
  min-width: 70px;
  width: min-content;
  max-width: 270px;
  padding: 0 5px;
}

.saveBtn {
  background-color: #f07663;
  color: white;
  width: fit-content;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.saveBtn span {
  font-size: 16px;
  position: relative;
  top: 1px;
  cursor: pointer;
}

.toggleContainer {
  margin-top: 5px;
  margin-left: 20px;
  border: 2px solid #f07663;
  height: 16px;
  width: 40px;
  border-radius: 9px;
  background-color: #fafafa;
  position: relative;
  cursor: pointer;
}

.toggleOn {
  background-color: #f07663;
}

.toggleBtn {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #fafafa;
  border: 1px solid grey;
  position: absolute;
  bottom: -4px;
  left: -10px;
  z-index: 2;
  box-shadow: 0px 0px 2px #000;
}

.isToggled {
  left: 15px;
  box-shadow: 0px 0px 2px 5px rgb(200 200 200 / 30%);
  transition: 0.5s ease-in position;
}

.list {
  color: inherit;
  textdecoration: inherit;
}

.list:hover {
  color: blue;
  text-decoration: underline;
}
