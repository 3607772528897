.masterContainer {
  background-color: rgb(249, 249, 249);
  height: 100vh;
}

.listPaperContainer {
  padding: 25px;
}

.breadcrumbText {
  font-size: 14px;
}

.breadcrumbLink {
  color: #999999;
  text-decoration: none;
}

.breadcrumbLink:hover {
  color: #999999;
  text-decoration: none;
}

.topBox {
  padding: 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createUser {
  color: #f07663;
  text-decoration: none;
  cursor: pointer;
}

.importUserButtonContainer {
  margin-left: 10px;
  border-left: 1px solid #dddddd;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.importUserButton {
  padding: 10px 20px 10px 20px;
  border: 1px solid #f07663;
  border-radius: 30px;
  color: #f07663;
  background: #ffffff;
  box-shadow: 1px 5px 10px 0px #00000029;
}

.headerNames {
  font-weight: bold;
}
.noDataFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 18px;
  font-weight: bold;
}
#businessLabel {
  width: 170px !important;
}
.userListTableCell {
  border: 'none';
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
}
