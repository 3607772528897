.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
  border-width: 2px 0px 0px 0px;
  border-color: #edeff1;
  border-style: solid;
}

.item {
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 20px;
  height: 100px;
  min-width: 150px;
  max-width: 150px;
  text-transform: capitalize;
  color: #5c5c5c;
  font-weight: bold;
}

.journeyName:hover {
  color: blue;
  text-decoration: underline;
  text-decoration-color: blue;
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: auto 10px;
}

.option input[type='checkbox'] {
  appearance: none;
}

.unchecked {
  border: 1px solid rgb(240, 118, 99);
  height: 18px;
  width: 18px;
  color: transparent;
}

.checked {
  font-size: 18px;
  color: white;
  background-color: rgb(240, 118, 99);
  border: 1px solid rgb(240, 118, 99);
  height: 18px;
  width: 18px;
  padding: 1px;
  align-items: center;
}

.checked span {
  position: relative;
  bottom: 4px;
}

.indicator {
  height: 20px;
  width: 10px;
  border: 1px solid white;
  clear: both;
  border-radius: 80px;
  margin-right: 5px;
}

.status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
