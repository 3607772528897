@import '../../../styles/colors';

// TODO: Extract colors to global file
.chip {
  border-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  text-transform: capitalize;
  color: #fff;
  background: $primary-color;
  display: inline-block;
  font-size: 0.875rem;

  &.rounded {
    border-radius: 99rem;
  }

  &.contained {
    border: none;
  }

  &.outlined {
    border: 1px solid transparent;
  }

  &.contained.primary {
    background-color: $primary-color;
    color: #fff;
  }

  &.contained.primary-light {
    background-color: lighten($primary-color, 25);
    color: $primary-color;
  }

  &.contained.success {
    color: #6ea088;
    background: #e4f5ee;
  }

  &.contained.error {
    color: #c8464f;
    background: rgba(255, 0, 0, 0.08);
  }

  &.outlined.primary,
  &.outlined.primary-light {
    background-color: transparent;
    color: $primary-color;
    border-color: $primary-color;
  }

  &.outlined.success {
    color: #6ea088;
    background: transparent;
    border-color: #6ea088;
  }

  &.outlined.error {
    color: #ff7f50;
    background: transparent;
    border-color: #ff7f50;
  }
}
