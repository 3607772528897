.option {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.option input[type='checkbox'] {
  cursor: pointer;
  appearance: none;
}

.unchecked {
  cursor: pointer;
  border: 1px solid rgb(240, 118, 99);
  height: 18px;
  width: 18px;
  color: transparent;
}

.checked {
  cursor: pointer;
  font-size: 18px;
  color: white;
  background-color: rgb(240, 118, 99);
  border: 1px solid rgb(240, 118, 99);
  height: 18px;
  width: 18px;
  padding: 1px;
  align-items: center;
}

.checked span {
  position: relative;
  bottom: 4px;
}

.option label {
  cursor: pointer;
  margin: 0;
  margin-left: 15px;
  font-size: 16px;
}

.customDropDownArrow {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid rgb(240, 118, 99);
}

.dropdownSearch {
  border-bottom: 1px solid rgb(240, 118, 99);
  position: relative;
  top: 0;
  max-height: 200px;
  overflow-y: scroll;
}

.customDropDownArrow::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.customDropDownArrow::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.customDropDownArrow::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
}
