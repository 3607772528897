.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #f07663;
  padding: 10px;
  margin: 30px 0px 0px 30px;
  cursor: pointer;
}

.text {
  margin-left: 10px;
}
