
.add-personalised-collection-modal-container {
    width: 40rem;
    /*min-height: 20rem;*/
    background-color: #ffffff;
    padding: 1rem;
    min-height: 60%;
    max-height: 95%;

    .personalised-collection-content {
        max-height: 35rem;
        overflow-y: auto;
    }

    .personalised-collection-header {
        color: #777777;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 500;
        width: 100%;
        text-align: center;
    }

    .border-top-1 {
        margin-top: 0.3rem;

        border-top: 1px solid rgb(224, 224, 224) !important;
    }
}
