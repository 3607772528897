.selected {
  color: #b43021;
  border: 1px solid #b43021;
  background-color: #fef3f2;
}

.deselected {
  border: 1px solid #9d9d9d;
  color: black;
  background-color: white;
}
