* {
  box-sizing: border-box;
}

.roleAssignBanner {
  width: 100%;
  position: relative;
}

.roleBannerText {
  position: absolute;
  top: 10%;
  left: 45%;
  font-size: 24px;
  font-weight: 700;
  color: #000000;
}

.roleBreadcrumbs {
  border: 1px solid #dedede;
  padding: 10px;
}

.mainContainer {
  padding: 20px;
  width: 100%;
}

.roleActionButtonContainer {
  // padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;

  .addItem {
    display: flex;
    border-right: 1px solid #dedede;
  }

  .addItemText {
    text-align: center;
    padding: 8px 15px 10px 15px;
    color: #f07663;
  }

  .importButton {
    margin-left: 20px;
    // border: 1px solid #f07663;
    border: 1px solid #00000042;
    border-radius: 50px;
    padding: 10px;
    // cursor: pointer;

    .importText {
      // color: #f07663;
      color: #00000042;
    }

    .importIcon {
      margin: 0 10px;
    }
  }
}

.roleAssignTableContainer {
  background-color: #ffffff;
  box-shadow: 0 0 5px #d3d3d3;
  padding: 30px;

  .roleAssignTableButton {
    padding: 0 0 30px 0;
    display: flex;
    justify-content: space-between;
  }
}

.tableClass {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  tr th,
  tr td {
    border: 1px solid #e0e3e7;
  }

  .customHeaderCell {
    width: 15%;

    img {
      width: 100%;
    }
  }

  .customSmallHeaderCell {
    width: 12%;

    img {
      width: 100%;
    }
  }

  .customHeader {
    height: 45px;
    border: none;
    border-right: 1px solid black;
    padding-left: 10px;
    color: black;
  }

  .featureList {
    padding: 10px 20px;
    width: 11%;
  }

  .arrowIconHeader {
    padding: 0 10px;
    color: #f2755f;
  }

  .feature {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: fit-content;
    cursor: pointer;
  }

  .headerRole {
    text-align: center;
    padding: 10px 20px;
    width: 11%;
  }

  .emptyCell {
    width: 11%;
    background-color: #d3d3d31f;
  }

  .headerRoleList {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
  }

  .switchCell {
    text-align: center;
    padding: 10px;
    width: 11%;
  }
}

.indicationContainer {
  display: flex;
  margin: 15px;

  .inactiveIndication {
    height: 20px;
    width: 10px;
    background-color: #e9e9ea;
    margin-right: 10px;
    border-radius: 50px;
  }

  .activeIndication {
    height: 20px;
    width: 10px;
    background-color: #f2755f;
    margin-right: 10px;
    border-radius: 50px;
  }
}
