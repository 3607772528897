@import '../../../styles/colors';

#loadingButton {
  position: relative;
  overflow: hidden;

  &:disabled.loading {
    color: transparent;
  }
  

  .loaderContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    z-index: 10;
  }
  .progess {
    color: $disabled-color;
  }
}
