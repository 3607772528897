.container {
  /* height: 75px; */
  max-height: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -2px;
  color: black;
  background-color: #ffffff;
}

.title {
  margin-right: -2px;
  /* to hide overlapped margin */
  max-height: 100px;
  width: 240px;
  color: #757575;
  border: 2px solid #e3e3e3;
  padding: 10px;
  overflow: visible;
}

.customInput {
  background-color: transparent;
  padding: 10px;
  text-indent: 10px;
  color: black;
  font-size: 16px;
  border: 2px solid #e3e3e3;
  outline: none;
  width: 400px;
  align-self: stretch;
}

.customDropdownArrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none' /></svg>");
  background-repeat: no-repeat;
  padding-left: 3px;
  background-position-x: 95%;
  background-position-y: 50%;
  cursor: pointer;
}

.specsTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headRow {
  color: black;
  background-color: #f7f7f7;
  font-weight: 400;
}

.option {
  border-radius: 5px;
  padding: 10px 3px 10px 3px;
  background-color: whitesmoke;
}

.container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
}
