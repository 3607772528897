.container {
	padding: 0.4em 0;
	height: 100vh;
	min-height: 100vh;
	font-size: 12px;
	font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
}

.main {
	background-color: #fff;
	display: flex;
	max-width: 96%;
	margin-bottom: 1rem;
	height: 80%;
	margin: auto;
	justify-content: space-between;
	gap: 20px;
}

.stepperContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 30%;
	height: 80%;
	margin: 2rem auto;

	.btns {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.4em;
	}
}