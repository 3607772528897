.checkout-form-container {
  height: 45rem;
  width: 45rem;
  background-color: white;
}

.stepper-container {
  height: 90%;
  display: flex;
  justify-content: center;
  padding: 2rem 4rem 4rem 4rem;
}

.login-form-container {
  margin: 1rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 30px;
}

.address-listing-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  height: 20rem;
  overflow: auto;
}

.address-listing-card {
  border-radius: 10px;
  background-color: #f7f7f7;
  margin: 1rem;
  text-align: left;
  padding: 1rem 0.5rem;
  border-style: dashed;
  border-color: #b7b7b7;
  cursor: pointer;
  border-width: 2px;
  height: fit-content;
}

.address-listing-card.active {
  border-color: #f2755f;
}

.address-line-item {
  margin: 0.3rem 1rem;
  font-size: 14px;
  color: #767676;
}

.address-line-item-name {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.address-form-container {
  margin: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.address-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 0rem 4rem;
}

.address-group-one {
  grid-area: 2 / 1 / 3 / 3;
}
.address-group-two {
  grid-area: 3 / 1 / 4 / 3;
}
/*.address-group-three { grid-area: 6 / 1 / 7 / 3; }
.address-group-four { grid-area: 7 / 1 / 7 / 3; }*/

.add-new-address-btn {
  text-align: end;
  color: #f2755f;
  font-size: 14px;
  font-weight: 700;
  margin: 1rem 0rem;
  cursor: pointer;
}
.shipment-user-information-header {
  text-transform: uppercase;
  font-weight: bold;
  text-align: initial;
  font-size: 14px;
  margin: 0.5rem 0.5rem 0.5rem 0rem;
}
.customer-info-key {
  text-align: initial;
}
.product-listing-container {
  margin: 1rem 0rem 0rem 1.5rem;
}
.payment-mode {
  margin: 1rem 1.5rem 0rem 0rem;
}
.customer-info-value {
  text-align: initial;
}
.customer-info-colon {
  margin: 0rem 0.2rem 0rem 0.2rem;
}
.line-separator {
  border-left: 2px solid #ebebeb;
  height: 80%;
  margin: auto;
}
.cart-listing-summary {
  max-height: 10rem;
  width: 110%;
  overflow: auto;
}
.center-align-content-name {
  margin: auto;
  overflow: hidden;
  width: 100%;
}
.center-align-content {
  margin: auto;
}
.product-total-header {
  text-align: left;
}
.product-total-summary {
  /*    margin: 1rem;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: repeat(1, 1fr);*/
  display: grid !important;
  grid-template-columns: 3fr 4fr 2fr;
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  font-size: 12px;
  margin: 1rem 0rem 1rem 0rem;
}
.product-total-value {
  font-weight: bold;
  text-align: right;
}
.cart-listing-container-summary {
  display: grid !important;
  grid-template-columns: 3fr 4fr 1fr;
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  font-size: 12px;
}
.summary-price-info {
  font-size: 12px;
}
.cart-listing-img-summary {
  height: 60px;
  width: 50px;
  margin: 0.5rem;
  cursor: pointer;
  border: 1px solid #d2d2d2;
  box-shadow: -2px 3px 15px 0px #eaeaea !important;
}
.cart-listing-name-dotted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666;
}
.order-summary-container {
  display: grid;
  grid-template-columns: 1fr 10px 1fr;
  grid-template-rows: 25rem;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 0rem 2rem;
  height: 25rem;
}
.cart-listing-item-price {
  color: #666666;
}

.address-field {
  width: 100%;
  margin: 0.3rem 0rem !important;
}

.address-item {
  margin: 0rem 0.3rem;
}

.adddess-empty-img {
  height: 15rem;
  width: 15rem;
}

.address-empty-text {
  font-size: 14px;
  font-weight: 600;
  color: #767676;
}

.successful-purchase-img {
  height: 15rem;
  margin: 3rem;
}

.successful-purchase-text {
  font-size: 18px;
  font-weight: 600;
  color: #767676;
  margin-bottom: 0.5rem;
}

.change-number-alert {
  margin: 1rem;
  font-size: 12px;
}

.change-number-btn {
  font-size: 12px !important;
}

.step-nav-btn {
}
.login-form-flex {
  display: flex;
  flex-direction: row;
}
.login-form-input-field {
  width: 90%;
}
.login-form-validator-field {
  width: 10%;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.payment-page-btn {
  font-size: 14px;
}

.steps-container {
  justify-content: center;
}

.whatsApp-icon {
  margin: 1rem;
  height: 5rem;
}

@media screen and (max-width: 600px) {
  .cart-listing-summary {
    max-height: none;
    width: 100%;
    overflow: unset;
  }
  .product-listing-container {
    margin: 0rem;
  }
  .order-summary-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    overflow: auto;
    height: 14rem;
  }
  .checkout-form-container {
    height: 39rem;
    flex-direction: column;
    display: flex;
    width: 100%;
  }
  .login-form-container {
    height: 15rem;
  }
  .address-listing-container {
    grid-template-columns: repeat(1, 1fr);
    height: 10rem;
  }
  .address-form-container {
    height: 15rem;
  }
  .address-line-item {
    font-size: 12px;
  }
  .address-listing-card {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  .add-new-address-btn {
    font-size: 12px;
    margin: 0.3rem 0rem;
  }
  .address-grid-container {
    margin: 0rem;
    overflow: auto;
    height: 10rem;
  }

  .change-number-btn {
    font-size: 12px !important;
  }

  .change-number-alert {
    margin: 0.5rem !important;
    font-size: 12px !important;
  }
  .step-nav-btn {
    font-size: 12px !important;
  }
  .MuiFormControlLabel-label {
    font-size: 12px !important;
  }
  .address-item .MuiFormLabel-root {
    font-size: 12px;
    line-height: 1;
  }
  .address-item .MuiInputBase-input {
    font-size: 12px;
  }
  .address-item .MuiOutlinedInput-input {
    padding: 17.5px 14px;
  }
  .login-form-grid .MuiFormLabel-root {
    font-size: 12px;
    line-height: 1;
  }
  .login-form-grid .MuiInputBase-input {
    font-size: 14px;
  }
  .login-form-grid .MuiInputBase-input {
    font-size: 14px;
  }
  .successful-purchase-img {
    height: 10rem;
    margin: 2rem;
  }
  .adddess-empty-img {
    height: 10rem;
    width: 10rem;
  }
  .successful-purchase-text {
    font-size: 16px;
  }
  .buy-now-btn {
    font-size: 10px !important;
  }
  .payment-page-btn {
    font-size: 12px !important;
  }
  .stepper-container {
    padding: 0rem;
  }
}
