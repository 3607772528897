.canvas {
  width: 3000px;
  height: 3000px;
  margin-left: 330px;
  overflow: hidden;
  background-image: url('./canvas.svg');
  background-repeat: repeat;
}

.canvas > * {
  height: 100%;
  min-height: 100%;
  width: 100%;
}
