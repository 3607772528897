.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  cursor: default;
}

.addNode,
.closeAddNode {
  margin-top: -13px;
  margin-left: 5px;
  cursor: pointer;
}

.inPort,
.outPort {
  margin-left: 35px;
  position: relative;
  top: -12px;
}

.inPort {
  top: 0px;
}

.remove {
  z-index: 100;
  position: absolute;
  top: -10px;
  left: 80px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 3px 3px rgb(204, 205, 207);
  border: 1px solid rgb(236, 236, 236);
  transition: 0.3s;
  background: #292929;
  color: #fff;
  display: none;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  font-size: 11px;
}

.remove:hover {
  background: rgb(204, 204, 204);
  color: #000;
}

.container:hover .remove {
  display: flex;
}

.container:hover .nodeBody {
  border: 1px solid rgb(212, 212, 212);
}
