.settings {
  border: 2px solid #f07663;
  background-color: white;
  box-shadow: 2px 2px 1px #00000029;
  padding: 10px;
  z-index: 2;
  position: absolute;
  top: 50px;
  left: -40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.input {
  border: 0;
  outline: 0;
  background: transparent;
  font-size: larger;
  border-bottom: 1px solid grey;
  padding-top: 15px;
}
.dateContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.heading {
  padding-top: 15px;
  color: orangered;
}
.to {
  position: relative;
  padding-top: 20px;
  font-weight: bolder;
}
.settingText {
  color: #666666;
  font-weight: bold;
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border: 1.5px solid #e7e7e7;
  border-radius: 5px;
}

.select {
  margin-top: 10px;
  outline: none;
  border: none;
  padding: 10px 10px 10px 0px;
  background: #f1f1f1;
}
