.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.center {
  text-align: center;
}

.l-m0 {
  margin: 0;
}

.l-mt0 {
  margin-top: 0;
}

.l-mr0 {
  margin-right: 0;
}

.l-mb0 {
  margin-bottom: 0;
}

.l-ml0 {
  margin-left: 0;
}

.l-mx0 {
  margin-left: 0;
  margin-right: 0;
}

.l-my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.l-m1 {
  margin: 0.5rem;
}

.l-mt1 {
  margin-top: 0.5rem;
}

.l-mr1 {
  margin-right: 0.5rem;
}

.l-mb1 {
  margin-bottom: 0.5rem;
}

.l-ml1 {
  margin-left: 0.5rem;
}

.l-mx1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.l-my1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.l-m2 {
  margin: 1rem;
}

.l-mt2 {
  margin-top: 1rem;
}

.l-mr2 {
  margin-right: 1rem;
}

.l-mb2 {
  margin-bottom: 1rem;
}

.l-ml2 {
  margin-left: 1rem;
}

.l-mx2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.l-my2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.l-m3 {
  margin: 2rem;
}

.l-mt3 {
  margin-top: 2rem;
}

.l-mr3 {
  margin-right: 2rem;
}

.l-mb3 {
  margin-bottom: 2rem;
}

.l-ml3 {
  margin-left: 2rem;
}

.l-mx3 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.l-my3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.l-m4 {
  margin: 4rem;
}

.l-mt4 {
  margin-top: 4rem;
}

.l-mr4 {
  margin-right: 4rem;
}

.l-mb4 {
  margin-bottom: 4rem;
}

.l-ml4 {
  margin-left: 4rem;
}

.l-mx4 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.l-my4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.l-p0 {
  padding: 0;
}

.l-pt0 {
  padding-top: 0;
}

.l-pr0 {
  padding-right: 0;
}

.l-pb0 {
  padding-bottom: 0;
}

.l-pl0 {
  padding-left: 0;
}

.l-px0 {
  padding-left: 0;
  padding-right: 0;
}

.l-py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.l-p1 {
  padding: 0.5rem;
}

.l-pt1 {
  padding-top: 0.5rem;
}

.l-pr1 {
  padding-right: 0.5rem;
}

.l-pb1 {
  padding-bottom: 0.5rem;
}

.l-pl1 {
  padding-left: 0.5rem;
}

.l-py1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.l-px1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.l-p2 {
  padding: 1rem;
}

.l-pt2 {
  padding-top: 1rem;
}

.l-pr2 {
  padding-right: 1rem;
}

.l-pb2 {
  padding-bottom: 1rem;
}

.l-pl2 {
  padding-left: 1rem;
}

.l-py2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.l-px2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.l-p3 {
  padding: 2rem;
}

.l-pt3 {
  padding-top: 2rem;
}

.l-pr3 {
  padding-right: 2rem;
}

.l-pb3 {
  padding-bottom: 2rem;
}

.l-pl3 {
  padding-left: 2rem;
}

.l-py3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.l-px3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.l-p4 {
  padding: 4rem;
}

.l-pt4 {
  padding-top: 4rem;
}

.l-pr4 {
  padding-right: 4rem;
}

.l-pb4 {
  padding-bottom: 4rem;
}

.l-pl4 {
  padding-left: 4rem;
}

.l-py4 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.l-px4 {
  padding-left: 4rem;
  padding-right: 4rem;
}
