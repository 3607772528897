@import "colors";

* {
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
}
.baseText {
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif; 
}

.primaryText {
  @extend .baseText;
  color: $primary-color;
}

.disabledText {
  @extend .baseText;
  color: $disabled-color;
}

.headerText {
  color: $header-text;
}

.normalText {
  color: $normal-text;
}

.hoverable {
  cursor: pointer;
}

.zoomOnHover:hover {
  transition: 0.1s;
  transform: scale(1.05);
}

.visibility-hidden {
  visibility: hidden;
}
.fR{
  float: right;
}
.fL{
  float: left;
}
.mR10{
  margin-right: 10;
}
.mL10{
  margin-left: 10;
}

a:hover {
  color: $primary-color !important;
}
