.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: whitesmoke;
}
.search {
  border: 1px solid grey;
  border-radius: 90px;
  padding-left: 10px;
  width: 280px;
  background-color: white;
}

.searchInput {
  outline: none;
  border: none;
}

.searchinput:focus {
  outline: none;
  border: none;
}

.reset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  border-radius: 90px;
  padding: 5px;
  width: 100px;
  cursor: pointer;
}

.resetText {
  font-size: 15px;
  padding-right: 10px;
  color: #f2755f;
}

.dateFilter {
  display: flex;
  flex-direction: row;
}
.date {
  margin-left: 30px;
}
