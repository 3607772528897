.journeyContainer {
  margin: 30px;
  margin-top: 10px;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(209, 206, 206);
  border-style: solid;
  width: fit-content;
  min-width: 96%;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.nda {
  text-align: center;
  color: grey;
  padding: 40px;
  font-weight: bold;
}
