.container {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  width: 80px;
  height: auto;
  cursor: grab;
}

.container:active {
  cursor: grabbing;
}

.itemText {
  color: #707070;
  font-size: 14px;
  text-align: center;
  text-transform: capitalize;
}
