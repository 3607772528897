@import "./variables";

@mixin props {
  border-radius: 0 !important;
  font-weight: inherit !important;
}
@mixin button {
  @include props;
  width: 200px;
  height: 40px;
}
@mixin textInherit {
  text-transform: inherit !important;
}

.buttonPrimary {
  @include button;
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
  background-color: $primary-color !important;
  color: $primary-white !important;
  &:hover {
    background-color: none !important;
  }
}
.buttonSecondary {
  @include button;
  @include props;
  @include textInherit;
  border: 1px solid $primary-color !important;
  background-color: #ffffff !important;
  color: $seconday-text-color !important;
}

.buttonSmall {
  @include props;
  @include textInherit;
  border: none !important;
  background-color: $bg-orange-light;
  height: 30px;
  font-size: $font-size-t12;
}

.buttonCancel {
  @include button;
  @include props;
  border: 1px solid $seconday-border !important;
  color: $seconday-text-color !important;
  background-color: #ffffff !important;
}
