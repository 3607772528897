.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f5bd4f;
  border-radius: 20%;
  width: 70px;
  max-width: 100px;
  padding: 2px 15px 2px 2px;
  margin: -10px 0px 0px 0px;
}

.text {
  font-weight: bold;
  padding-left: 5px;
}
