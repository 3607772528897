@import '../../../../../../styles/colors';

.campaignReportTableBody {
  tbody tr {
    border-bottom: 1px solid $border-color;
  }

  td {
    font-size: 1rem;
    font-weight: 600;
  }
  a:hover {
    color: $primary-color;
  }
}
