.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 20px 0px;
  margin-top: 10px;
}

.text {
  margin: 10px 0px 0px 20px;
  font-size: 22px;
  color: black;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.button {
  margin-left: 10px;
  padding: 7px;
  background-color: #f2755f;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.actionContainer {
  margin-right: 50px;
}
