@import '../../../../../../styles/colors';

.variantCard {
	display: flex;
	flex-direction: column;
	padding: 10px 0;

	.variantInfo {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;
		border-radius: 0 1px 0 1px;
		padding: 10px 10px 10px 0;
		border: 1px solid #E9E9E9;
		border-bottom: 0px;
		
		.name {
			padding: 0.5rem 3rem 0.5rem 1rem;
			border-radius: 0 32px 32px 0;
			background-color: $primary-color;
			color: white;
			font-size: 20px;
			font-weight: 600;
		}
	
		.statuses {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 3rem;

			.statusCount {
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				color: #333333;
		
				span {
					color: #475569;
					margin-right: 10px;
				}
		
				img {
					height: 24px;
					width: 24px;
					margin-right: 10px;
				}
			}
		}
	
	}

	table {
		border: none;
		border-radius: 0;
	}

	.header {		
		tr th {
			border-radius: 0;
			font-size: 16px !important;
			font-weight: 600px !important;
			color: #62687F !important;
			border: 1px solid #EEEEEE;
			background-color: #FBFBFB !important;
		}
	}

	.cell {
		border: 1px solid #EEEEEE;
		padding: 8px;
		text-align: center;
		font-size: 16px !important;
		font-weight: 500 !important;
		color: black;
	}
}
