@import '../../../styles/colors';

#tenantFieldHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#tenantFieldFilters {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-block: 1rem 1.25rem;
  justify-content: space-between;
  .entitySelect {
    min-width: 150px;
  }
  .tenantFieldSearch {
    min-width: 300px;
    margin-right: 1.5rem;
  }

  .formControl,
  .entitySelect,
  .tenantFieldSearch {
    label {
      color: $normal-text !important;
    }
    &:focus-within {
      label {
        color: $primary-color !important;
      }
    }
  }
}
