.popUp {
  z-index: 30;
  position: fixed;
  top: 37%;
  left: 55%;
  padding: 30px;
  min-height: 100px;
  min-width: 100px;
  max-height: 600px;
  overflow: auto;
  transform: translate(-50%, -50%);
  background-color: whitesmoke;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 15px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
}

.heading {
  color: #333333;
  font-weight: bolder;
  font-size: 18px;
  margin-left: 5px;
  padding-bottom: 20px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: grey;
  width: 80%;
}

.button {
  cursor: pointer;
  font-size: 16px;
  color: whitesmoke;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  position: relative;
  align-self: flex-end;
}

.modal {
  outline: none;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 10em;
  overflow: auto;
  height: 2em;
}

.popUp::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: whitesmoke;
}

.popUp::-webkit-scrollbar-thumb {
  background: grey;
}

.errorMsg {
}

.errorText {
  margin-left: 24%;
  margin-top: 3%;
  font-size: 20px;
}
