.product-tile-pricing {
  display: flex;
}
.product-tile-cart {
  position: relative;
  margin-left: auto;
  margin-right: 1rem;
}
.product-tile-save-price {
  margin-left: 0.4rem;
  font-weight: 100;
  color: #707070;
  background-color: #acf491;
  padding: 0.2rem;
  border-radius: 0.4rem;
  font-size: 10px !important;
}
.product-tile-mop-block {
  height: 1rem;
}
.product-tile-mop {
  font-size: 10px;
  text-decoration: line-through;
  text-align: left;
  font-weight: 100;
  color: #ff5555;
  margin-right: 0.5rem;
}
.product-tile-footer {
  display: flex;
}
.product-tile-mrp {
  display: inline-block;
  /*margin-left: 1rem;*/
  font-weight: 600;
  font-size: 14px !important;
  font-family: Nunito;
}
.product-title {
  display: inline-block;
  float: left;
  margin-left: 1rem;
  font-family: Nunito;
}
.product-container {
  display: flex;
  flex-direction: column;
}
.product-content {
  /*margin-top: 0.4rem;*/
  text-align: left;
  color: #555555;
  font-weight: bold;
}

.product-content-description {
  /*margin-top: 0.4rem;*/
  text-align: left;
  color: #555555;
}
.product-tile-header {
  width: 100%;
  height: 20%;
}
.product-tile-content {
  display: block;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
  width: 100%;
  height: 80%;
}
.product-cancel {
  display: inline-block;
  float: right;
  margin-right: 1rem;
  margin-top: 0.5rem;
}
.product-font {
  font-size: 12px;
  color: #000000;
}

.product-tile-base-price {
  font-weight: 900;
}
.image-container {
    height: 11.5rem;
    width: 11.5rem;
    position: relative;
    object-fit: cover;
    object-position: 50% 0;
}
/*Product popup card css*/
.product-card-popup-container {
  width: 50rem;
  height: 40rem;
  background-color: #ffffff;
  overflow: auto;
}

.product-card-popup-body {
  /*display: inline-block;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-row-gap: 20px;
  width: 100%;
  /*height: 60%;*/
}
.product-card-popup-image-container {
  height: 18rem;
  width: 18em;
  /*min-height: 16rem;*/
  /*padding: 1rem;*/
  object-fit: cover;
  object-position: 50% 0%;
}
.product-card-popup-image {
  /*padding: 3rem;*/
  /*float: left;*/
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
}

.align-center {
  display: flex;
  justify-content: left;
  align-items: center;
}

.product-card-popup-info {
  height: 80%;
  width: 80%;
}
.product-card-pop-up-title {
  padding: 0rem 0rem 1rem 0rem;
  font-size: 20px;
  font-weight: 600;
}
.product-details-table {
  overflow: auto;
  /*height: 200px;*/
}
.product-card-popup-key {
  vertical-align: bottom;
  color: #707070;
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: 600;
}

.product-att {
  padding: 0.4rem 0px;
}

.product-font-size {
  font-size: 12px !important;
  color: #707070;
}
.product-card-popup-base-mrp {
  display: inline-block;
  font-weight: 600;
  font-size: 16px !important;
  font-family: Nunito;
  font-weight: 600;
}
.product-card-popup-mop {
  text-decoration: line-through;
  text-align: left;
  font-weight: 300;
  padding-right: 5px;
  color: #ff5555;
}
.product-card-popup-value {
  /*padding-left: 0.8rem;*/
  font-weight: 600;
  /*max-width: 10rem;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*font-size: 16px;*/
  font-weight: 600;
}
.product-card-popup-colon {
  vertical-align: bottom;
}
.product-card-popup-row {
  /*padding: 0.5rem 0.8rem 0.5rem 0rem ;*/
}
.product-card-footer {
  padding: 1rem 0rem;
}
.product-card-button-chips {
  padding: 0rem 0.5rem 0rem 0.5rem;
  font-size: 16px;
}
.variant-btn {
  margin: 1rem;
  cursor: pointer;
  border: 1px solid #d2d2d2;
  padding: 0.4rem;
  border-radius: 1rem;
  font-size: 12px;
  width: 5rem;
  text-align: center;
  color: #707070;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.variant-btn.active {
  border: 2px solid #f4907e;
  font-weight: 600;
  background-color: #fff4f2;
  color: #f4907e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.variant-label {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}
.product-info {
  position: relative;
  overflow: auto;
  height: 30rem;
  width: 25rem;
}
.add-to-cart {
  width: 100%;
  justify-content: center;
  display: flex;
}

.add-to-cart-qty {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-to-cart-btn {
  width: 100%;
}

.remove-from-cart-btn {
  width: 100%;
}

.action-container {
  width: 100%;
  padding: 0rem 4rem;
}

@media screen and (max-width: 600px) {
  .product-card {
    align-items: center;
    display: flex;
    height: 100%;
  }
  .image-container {
    height: 18rem !important;
    width: 15rem;
  }
  .product-details-table {
    height: auto;
  }

  /*Product popup card css*/
  .product-card-popup-container {
    width: 100% !important;
    height: 40em !important;
    overflow: auto;
  }

  .product-card-popup-image {
    padding: 1rem 1rem 0rem 1rem;
    float: none;
    text-align: center;
  }
  .product-card-popup-image-container {
    height: 13rem;
    width: 13rem;
    padding: 0rem;
  }
  .product-card-popup-body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    min-height: 80%;
  }
  .product-card-pop-up-title {
    padding: 0rem;
  }
  .product-card-footer {
    padding: 0rem;
    margin: 0rem;
  }
  .product-card-border-top {
    border-top: 1px solid #dddddd;
  }
  .product-card-popup-info {
    padding: 2rem;
    width: 100%;
    height: 100%;
  }
  .variant-btn {
    margin: 0.5rem;
    padding: 0.3rem;
  }

  .product-card-popup-key {
    font-size: 12px !important;
  }

  .product-card-popup-value {
    font-size: 14px;
  }
  .variant-label {
    font-size: 14px;
  }
  .add-to-cart {
    justify-content: center;
    position: unset;
    height: 100%;
  }
  .add-to-cart-btn {
    height: 100%;
    width: 100%;
    font-size: 14px;
    height: 3rem;
  }
  .popup-header-section {
    display: flex;
    height: 8%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 1px;
    width: 100%;
    z-index: 100;
  }
  .product-card-arrow-upward-icon {
    width: 1rem;
    height: 1.5rem;
  }
  .product-card-arrow-upward-icon-container {
    margin: 0px 7px;
  }
  .product-card-cta-footer {
    box-shadow: rgba(0, 0, 0, 0.15) 0px -1px 3px 0px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .remove-from-cart-btn {
    height: 100%;
    width: 100%;
    font-size: 14px;
  }
  .product-thumbnail-img-container {
    width: 230px !important;
  }

  .product-info {
    position: unset;
    overflow: unset;
    height: auto;
    width: auto;
    padding: 0rem;
    padding-bottom: 1rem;
  }

  .stock-status {
    margin: 0rem !important;
  }
}

.product-card-container {
  box-shadow: -2px 3px 15px 0px #eaeaea !important;
  padding: 0px !important;
  min-width: unset !important;
}

.product-thumbnail {
  height: 76px;
  width: 57px;
  display: inline-block;
  margin: 0.5rem;
  border: 1px solid #e1e1e1;
}

.product-thumbnail.active {
  border: 2px solid #f4907e;
}

.thumbnail-img-container-align-center {
  justify-content: center !important;
}

.thumbnail-img-container-align-initial {
  justify-content: initial !important;
}

.product-thumbnail-img-container {
  margin: 1rem;
  width: 270px;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.stock-status {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
  padding: 0.4rem;
}

.in-stock-status {
  color: #12a012;
}

.coming-soon-status {
  color: #ff1e1e;
}

.product-cart-info {
  /*position: absolute;*/
  /*bottom: 0px;*/
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
  /*opacity: 0.9;*/
}

.product-price {
  display: flex;
  align-items: center;
}

.image-personalisation-container{
  position: absolute;
  width: 3.6rem;
  height: 0.8rem;
  border-bottom-right-radius: 4px;
  z-index: 1;
  background-color: #E37B1E;
}
.img-personalisation-text{
  font-size: 8px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
