.showValidOffers,
.showInvalidOffers {
  border-bottom: 1px solid rgb(240, 118, 99);
  position: relative;
  top: 3px;
  padding-left: 40px;
  padding-top: 10px;
  height: 100px;
  overflow-y: scroll;
  border: 2px solid orangered;
}

.label {
  font-weight: bolder;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input {
  border: 2px solid #e3e3e3;
  background-color: transparent;
  padding: 10px;
  color: black;
  font-size: 16px;
  outline: none;
  width: 240px;
}
