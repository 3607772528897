.container {
  text-align: left;
  background-color: white;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-direction: column;
}

.description {
  color: rgba(51, 51, 51, 0.7);
}
