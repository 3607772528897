@import './variables';

.fileUpload {
  .download {
    float: right;
    display: flex;
  }
  .download:hover {
    border: none;
  }
}
