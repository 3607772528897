.cart {
  grid-area: 1 / 2 / 2 / 3;
  margin: 10px 0px;
}

.cart-icon {
  height: 25px;
  width: 25px;
}
.empty-cart-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
  flex-direction: column;
}
.invalid-cart-listing-name {
  text-align: initial;
  text-transform: capitalize;
  font-weight: 780;
  padding: 0rem 0.5rem;
  color: #707070;
  font-size: 20px;
}
.invalid-cart-cta-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.removed-line-item {
  position: relative;
}
.sold-out-line-item {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 1;
  display: flex;
  justify-content: center;
}
.sold-out-line-item-text {
  font-size: 28px;
  font-weight: 700;
  background: white;
  text-transform: capitalize;
}

.o-50 {
  opacity: 50%;
}

.m-0-1 {
  margin: 0rem 1rem;
}
.p-1 {
  padding: 1%;
}
.disabled {
  cursor: not-allowed !important;
}
.invalid-line-item-remove-icon {
  margin-top: 1%;
  right: unset !important;
}
.valid-line-item {
  border: 1px solid green;
}
.invalid-line-item {
  border: 1px solid red;
}
.out-of-stock-line-item {
  border: 1px solid grey;
}
.cart-empty-text {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}
.add-to-cart-text {
  text-align: center;
  color: #666666;
}
.cart-listing-header {
  display: flex;
  padding: 1rem 0rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #dedede;
}

.cart-listing {
  height: 30rem;
  overflow: auto;
}

.cart-listing-header-text {
  color: #707070;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
}

.cart-tc {
  font-size: 12px;
  color: #929292;
}

.cart-item-remove-icon {
  height: 20px;
  width: 20px;
  margin: 1rem;
  cursor: pointer;
}

.cart-listing-img {
  height: 85px;
  width: 70px;
  margin: 0.5rem;
  cursor: pointer;
  border: 1px solid #d2d2d2;
  box-shadow: -2px 3px 15px 0px #eaeaea !important;
}

.cart-listing-container {
  display: grid !important;
  grid-template-columns: 3fr 4fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.cart-item-qty {
  grid-area: 2 / 2 / 3 / 4;
}
.cart-item-img {
  display: inline;
  position: relative;
  grid-area: 1 / 1 / 3 / 2;
}

.cart-listing-item-price {
  font-weight: 700;
}

.cart-listing-item-qty {
  display: flex;
  width: 102px;
  /*border-radius: .5rem;
    width: 102px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
}

.cart-listing-item-qty-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #707070;
}

.remove-item-icon {
  position: absolute;
  right: 0;
}

.cart-summary-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.cart-summary-save-price {
  text-align: end;
  margin: 0rem 1rem;
  grid-area: 2 / 1 / 3 / 3;
  font-size: 14px;
  color: green;
}

.cart-summary-subtotal {
  font-weight: bold;
  font-size: 16px;
}

.cart-summary-total {
  font-weight: bold;
  font-size: 16px;
  text-align: end;
  margin: 0rem 1rem;
}

.cart-listing-name {
  font-size: 12px;
  color: #707070;
}

.checkout-form-model-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-now {
  text-align: left;
}

@media screen and (max-width: 600px) {
  .cart-listing {
    height: 15rem;
    background-color: #f3f3f3;
  }

  .cart-listing-item-price {
    font-size: 12px;
  }
  .cart-listing-name {
    font-size: 11px;
  }
  .cart-listing-header-text {
    font-size: 14px;
  }
  .cart-summary-subtotal {
    font-size: 14px;
  }
  .cart-summary-total {
    font-size: 14px;
  }
  .cart-summary-save-price {
    font-size: 12px;
  }
  .buy-now {
    text-align: center;
  }
  .cart-empty-text {
    font-size: 18px;
  }
  .add-to-cart-text {
    font-size: 12px;
  }
}

.chat-and-buy {
  text-decoration: none !important;
}
