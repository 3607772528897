.container {
  /* height: 75px; */
  max-height: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -2px;
  color: black;
  background-color: #ffffff;
}

.container span,
.multiSelectSpan {
  margin-right: -2px;
  /* to hide overlapped margin */
  max-height: 100px;
  width: 120px;
  color: #757575;
  border: 2px solid #e3e3e3;
  padding: 10px;
  /* height: 75px; */
  overflow: visible;
}

.save {
  text-transform: capitalize;
  cursor: pointer;
  padding-top: 20px;
  font-size: 16px;
  margin: 20px 0px 0px 40px;
  color: #333333;
  font-weight: bold;
  position: relative;
}

.save:active {
  color: #3e8e41;
  transform: translateY(4px);
}

.save:hover {
  color: orangered;
}

.multiSelect {
  display: flex;
  flex-direction: row;
  position: relative;
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  margin-left: 400px;
}
