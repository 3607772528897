@import '../../../../../../styles/colors';

.formControl {
  label {
    color: $normal-text !important;
    white-space: nowrap;
  }
  &:focus-within {
    label {
      color: $primary-color !important;
    }
  }
}
