#settingsBreadcrumbs {
  padding: 0.9rem;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;

  .link {
    font-size: 0.9rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    color: #777;
    font-weight: 400;
    text-decoration: none;
  }

  a.link {
    &:hover {
      color: #f26e5f;
      text-decoration: none;
    }
  }
}
