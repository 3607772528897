.modalTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25 !important;
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif; ;
  font-weight: 700 !important;
  font-size: 18 !important;
}
.buttonStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.migrateModalContainer {
  padding: 2rem;
  width: 86%;
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif; ;
  font-weight: 700 !important;
  position: relative;
  top:-50px
}
.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
}
.formField {
  display: flex;
  gap: 2rem;
  width: 100%;
}
.submitButton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.5rem;
}
.businessUnitStyle {
  display: flex;
  justify-content: space-between;
  width: 86%;
  height: 140px;
  margin-top: 24px;
}
.migrateImage {
  background-image: url('../Assets/storeManagment.png');
  color: #333333;
  width: 100%;
  height: 190px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  padding-top: 20px !important;
  padding-left: 40px !important;
}
.migrateTitle {
  font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif; ;
  font-weight: 700;
  font-size: 24px !important;
}

.headerTitle {
  font-size: 22px;
  font-weight: bold;
}
.alignContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tableHeader {
  background-color: red;
  width: 100%;
}
.root {
  width: "100%";
}
.container {
  height: 440;
}
.breadcrumbs{
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}