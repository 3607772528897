// Colors
$primary-background-color: #f9f9f9;
$primary-white: #fff;
$primary-color: #f07663;
$secondary-text-color: #777777;
$bg-orange-light: #fff9f7;
$error-text-color: #f74444;
$success-text-color: #19571c;
$seconday-border: #dddddd;
$seconday-text-color: #999999;

// Font Size
// 1 rem = 16px
$font-size-t10: 0.625rem; // 10px
$font-size-t12: 0.75rem; // 12px
$font-size-t14: 0.875rem; // 14px
$font-size-t16: 1rem; // 16px
$font-size-t18: 1.125rem; // 18px
$font-size-t20: 1.25rem; // 20px
$font-size-t24: 1.5rem; // 24px
$font-size-t28: 1.75rem; // 28px

// Spacing
$spacing-t10: 0.125rem; // 2px
$spacing-t9: 0.25rem; // 4px
$spacing-t8: 0.5rem; // 8px
$spacing-t7: 0.75rem; // 12px
$spacing-t6: 1rem; // 16px
$spacing-t5: 1.25rem;
$spacing-t4: 1.5rem; // 24px
$spacing-t3: 2rem; // 32px
$spacing-t2: 3rem; // 48px
$spacing-t1: 4rem; // 64px
