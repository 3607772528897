@import '../../../../../styles/colors';

#formControl {
  label {
    color: $normal-text !important;
    white-space: nowrap;
  }
  &:focus-within {
    label {
      color: $primary-color !important;
    }
  }

  .inputLabel {
    font-size: 1.25rem;
  }

  #select {
    width: 100%;
  }
}
