.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.node {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: -17.3ch;
  top: -18px;
}

/* .waitNode {
  margin-top: 10px;
  margin-left: 20px;
} */
.nodeText {
  font-size: 18px;
  margin-right: 30px;
  text-transform: capitalize;
  color: black;
  font-weight: 400;
  margin-left: 30px;
}

.decision {
  margin-top: -16px;
  display: flex;
  flex-direction: row;
}

.inPort,
.outPort {
  margin-left: 35px;
  position: relative;
  top: -12px;
}

.addNode {
  margin-top: 5px;
  cursor: pointer;
}
