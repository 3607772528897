@import '../../../../../../styles/colors';

.campaignTableBodyRow {
  td {
    color: darken($normal-text, 15);
  }

  .campaignName {
    max-width: 220px;
  }
  .viewDetailBtn {
    overflow: hidden;
    color: darken($normal-text, 15);
    span > span {
      margin-left: 1px;
      transition: 300ms ease transform;
      margin-top: -2px;
    }

    &.active {
      span > span {
        transform: rotate(-180deg);
      }
    }
  }
  .viewBtn {
    color: $disabled-color;
  }
}

#campaignCollapseRow {
  border-bottom: 1px solid $border-color;
  #campaignTableCell {
    padding: 0;
  }
}
