.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-area: order-info;
}

.table {
  border-collapse: collapse !important;
  margin-bottom: 20px;
  width: 100%;
  page-break-before: always;
}

.table tr {
  background-color: white;
  border: 1px solid #f3f3f3 !important;
}

.table tbody {
  margin-top: 100px;
}

.table th {
  text-transform: capitalize;
  text-align: left;
  background-color: #444;
  color: white;
}

.table th,
.table td {
  padding: 15px 10px;
  min-width: 50px;
  width: max-content;
  word-wrap: break-word;
  border: 0px !important;
}

.summary {
  width: 300px;
  /* margin-left: 75%; */
}

.summary table,
.summary tr {
  width: 100%;
  border: 0px !important;
}

.summaryRow td {
  padding: 5px !important;
  text-align: right !important;
}

.summary td:last-child {
  padding: 10px !important;
}

.summaryField + td {
  font-weight: bold;
  text-transform: capitalize;
}
