.container {
  cursor: pointer;
  padding: 10px 15px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.label {
  cursor: pointer;
  margin-left: 6px;
  font-size: 12px;
  font-weight: bold;
}
