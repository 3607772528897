.container {
  max-width: 100px;
  background: transparent;
}
.infoArea {
  background: transparent;
  width: max-content;
  margin-left: -130px;
}
.infoText {
  padding: 35px 30px 35px 30px;
  background-color: white;
  font-weight: bold;
  border: 2.5px dashed #f4c6bc;
  position: relative;
  top: -10px;
}

.infoArrow {
  position: relative;
  top: -12px;
  left: 42.5%;
}
