.container {
  margin-top: 20px;
  margin-left: 10px;
}

.textContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.templateIdField {
  width: 250px;
}
.textField {
  width: 100px;
}
