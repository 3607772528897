.container {
    gap: 1rem;
    max-width: 80%;
    margin: auto;
    margin-bottom: 1rem;
}

.heading {
    color: #F26E5F;
}

.form {
    display: grid;
    gap: 0.5em;

    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;

        .switch {
            display: flex;
            grid-column: span 2;
            gap: 0.5em;
        }
    }
}

.detailsTable {
    border-collapse: collapse !important;
    margin-bottom: 20px;
    page-break-before: always;
    background-color: #fff;
    border-radius: 8px;

    thead {
        white-space: nowrap;
    }

    th {
        text-align: left;
        border: 1px solid #E9E9E9 !important;
    }

    th,
    td {
        padding: 0.6em 1em;
        min-width: 100px;
        width: max-content;
    }
}

.extras {
    display: grid;
    gap: 1em;

    .head {
        display: flex;
        gap: 0.5em;
        align-items: flex-start;

        p {
            font-weight: 400;
            font-size: 20px;

        }
    }

    .body {
        background-color: #F2F2F2;
        padding: 1em;
        border-radius: 4px;
        box-shadow: 0px 4px 5px rgba(210, 210, 210, 0.25);
        padding: 1.4rem;
        display: grid;
        gap: 1rem;

        .select {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;
            max-width: 15vw;

            :focus,
            :hover {
                background-color: transparent;
            }

            .input {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                box-shadow: 0px 4px 5px rgba(210, 210, 210, 0.25);
                height: 6vh;
                padding: 0 0.4rem;
                font-size: 14px;
            }
        }

        .form {
            display: grid;
            gap: 0.5em;

            .fields {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 1em;

                .switch {
                    display: flex;
                    grid-column: span 2;
                    gap: 0.5em;
                }
            }
        }
    }
}

.icon {

    width: 1.6rem;
    margin-top: 0.125em;
    color: #F26E5F;
}