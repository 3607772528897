.container {
    gap: 1rem;
    max-width: 80%;
    margin: auto;
}

.heading {
    color: #F26E5F;
}

.form {
    display: grid;
    gap: 0.5em;
    margin-bottom: 1rem;

    .fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;

        .switch {
            display: flex;
            grid-column: span 2;
            gap: 0.5em;
        }
    }

}

.addField {
    background: #FFFFFF;
    border-left: 8px solid #F26E5F;
    box-shadow: 2px 2px 13px rgba(133, 133, 133, 0.25);
    border-radius: 4.32203px;
    padding: 1rem;
    display: grid;
    gap: 0.4rem;
    align-items: center;

    .labelInput {
        display: grid;
        grid-template-columns: 0.7fr 0.3fr;
        align-items: flex-end;
        align-content: flex-end;

        .formControl {
            margin: 0.5rem 1rem;
            border-style: solid;
            border-radius: 100px;
            min-width: 120px;
            justify-content: center;


            .select {
                // font-size: 12px;
                height: 35px;

                :focus,
                :hover {
                    background-color: transparent;
                }

                .menuItem {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.2rem;
                }

                .icon {
                    margin-right: 5px;
                    width: 100%;
                    max-width: 2rem;
                }
            }
        }
    }

    .actionGroup {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 0.5rem;
        width: full;

        .btns {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
        }

    }

    .btn {
        background: #F26E5F;
        border-radius: 100px;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 600;
        padding: 0.4rem 0.8rem;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

        :hover {
            background: #F26E5F;
            opacity: 0.8;
        }
    }
}

.fieldGroups {
    display: grid;
    gap: 0.5rem;
    margin-bottom: 1rem;
    background-color: #F2F2F2;
    padding: 0.6rem;
    border-radius: 4.32203px;
}

textarea:focus,
input:focus {
    outline: 0;
}