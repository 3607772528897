.container {
  /* flex-grow: 1; */
  display: flex;
  margin-top: 4px;
  overflow: scroll;
  object-fit: fill;
  /* overflow-y: hidden; */
}
.layer {
  position: relative;
  flex-grow: 1;
}
.dragDropArea {
  margin-top: 10px;
  background: transparent;
}

.saveBtn {
  background-color: orangered;
  color: white;
  text-align: center;
  width: fit-content;
  padding: 10px 50px;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
/* .backdrop {
  display: flex;
  margin-top: 4px;
  overflow: hidden;
  filter: blur(3px);
  cursor: not-allowed;
} */

.popUp {
  z-index: 30;
  position: fixed;
  top: 50%;
  left: 50%;
  min-height: 100px;
  min-width: 100px;
  transform: translate(-50%, -50%);
  height: max-content;
  width: max-content;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.popUp > div {
  background-color: orangered;
  color: white;
  text-align: center;
  width: fit-content;
  padding: 10px 50px;
  border-radius: 5px;
  margin-top: 15px;
  align-self: center;
}

.popUp > label {
  font-size: 14px;
}

.popUp > input {
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.9);
  border-radius: 5px;
  line-height: 20px;
  font-size: 16px;
}

.closeBtn {
  font-size: 32px;
  color: whitesmoke;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  position: relative;
  bottom: 80px;
  align-self: flex-end;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 10em;
  overflow: auto;
  height: 2em;
}

.container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: whitesmoke;
  border-radius: 80px;
}

.container::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 70px;
}
