.page-container {
  width: 468px !important;
  height: 630px;
  border: 1px solid #dddddd;
  box-shadow: 5px 2px 10px #00000029;
}

@media screen and (max-width: 600px) {
  .page-container {
    width: 300px !important;
    height: 460px !important;
  }
}

.page-effect {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 0;
  opacity: 0.7;
}

.cover-page-container {
  height: 100%;
}

.cover-page {
  height: 100%;
}

.collections-group-container {
  height: 18%;
  border-bottom: 1px solid #dddddd;
}
.table-collections-group {
  border-spacing: 25px 5px;
  border-collapse: separate;
  margin-right: auto;
  margin-left: auto;
  table-layout: fixed;
  height: 100%;
}
.add-cover-img {
  border: 1px dashed #e78e7a;
  border-radius: 19px;
  margin: 2rem;
  height: 85%;
}

.cover-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cover-img-active {
  height: 100%;
  background-position: center;
  background-size: cover;
}

.collections-group {
  margin: 0rem 4rem 0rem 3rem;
  overflow-y: hidden;
  height: 100%;
  overflow-x: auto;
}
.collections-group::-webkit-scrollbar-thumb {
  background-color: #f2755f;
  border-radius: 20px;
  /*border: 3px solid orange;*/
}
.collections-group::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}
.player-overlay {
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  margin: 2rem 0rem;
  top: 0;
  z-index: 1;
}
.collections-group::-webkit-scrollbar-track {
  background: #f2f2f2;
}

.cover-img-container {
  height: 85%;
}

.flipbook-text-header {
  color: #777777;
  font-size: 14px;
}

.flipbook-text-label {
  color: #bbbbbb;
  font-size: 12px;
}

.display-none {
  display: none;
}

.add-img-icon {
  text-align: center;
}

.modal-container {
  min-width: 40rem;
  /*min-height: 20rem;*/
  background-color: #ffffff;
  height: 40%;
}

.add-collections-modal-container {
  min-width: 40rem;
  /*min-height: 20rem;*/
  background-color: #ffffff;
  min-height: 60%;
  max-height: 95%;
}

.add-collections-modal-container:focus {
  outline-style: none;
}

.layout-selection {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  /*height: 80%;*/
}
.popup-close-section {
  position: absolute;
  right: 0;
  margin: 0.8rem;
  cursor: pointer;
}
.popup-header-section {
  position: relative;
  display: flex;
}
.modal-container:focus {
  outline-style: none;
}

.layout-selection-single-card {
  margin: 1rem;
  margin-right: 2rem;
  padding: 1rem 1rem 0.5rem 1rem;
  width: 8rem;
}
.layout-selection-double-card {
  margin: 1rem;
  margin-left: 2rem;
  padding: 1rem 1rem 0.5rem 1rem;
  width: 8rem;
}
.page-layout {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.page-layout-border {
  border: 1px dashed #f2755f;
  border-radius: 10px;
  height: 95% !important;
}

.height-100-per {
  height: 100% !important;
}

.height-96-per {
  height: 96%;
}

.height-95-per {
  height: 95%;
}

.height-48-per {
  height: 48%;
}

.height-50-per {
  height: 50%;
}

.height-4-per {
  height: 4%;
}

.page-layout-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loyout-type-popover-container {
  margin: 1rem;
}

.loyout-type-popover-line-item {
  padding: 0.5rem;
  cursor: pointer;
}

.border-bottom-1 {
  border-bottom: 1px solid #707070;
}
.border-top-1 {
  border-top: 1px solid #707070;
}

.edit-delete-icons-layout-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 2rem;
  background-color: #8581812b;
  border-radius: 4rem;
  padding: 0.5rem;
  z-index: 100;
}

.add-collection-modal-container {
  background-color: #dedede;
}

.mail-header {
  color: #777777;
  font-size: 16px;
  text-transform: uppercase;
  margin: 1rem;
  width: 100%;
}

.add-collection-modal-content {
  margin: 0% 20%;
  text-align: left;
}

.add-collection-modal-card {
  text-align: center;
  max-width: 10rem;
  /*padding: 1rem 0rem;*/
}

.display-flex {
  display: flex;
}

.text-primary-color {
  color: #707070;
}

.casa-color {
  color: #f2755f;
}
.elevate {
  z-index: 100;
}
.flipbook-image-container {
  position: absolute;
  width: 100%;
  /*z-index: 100;*/
  height: 100%;
}
.change-layout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 101;
  height: auto;
}

.change-layout-text {
  text-decoration: underline;
  cursor: pointer;
  font-size: 11px;
}

.modal-box-shadow {
  box-shadow: 0px 0px 0px 0px rgb(109 107 107 / 0%),
    0px 0px 0px 0px rgb(255 255 255 / 0%), 0px 1px 9px 3px rgb(177 177 177 / 7%);
}

.modal-add-layout-box-shadow {
  box-shadow: 0px 0px 0px 0px rgb(51 51 51 / 8%),
    0px 0px 0px 0px rgb(84 84 84 / 0%), 0px 0px 9px 0px rgb(0 0 0 / 69%);
}

.product-template-select {
  /*width: 70px;*/
  font-size: 13px !important;
}

.product-template-text {
  font-size: 13px;
}

.product-selection-card-container {
  min-height: 12rem;
  display: flow-root;
  justify-content: center;
  align-items: center;
}

.choose-products-hierarchy-modal-container {
  width: 80%;
  height: 80%;
  background-color: #ffffff;
}

.choose-products-hierarchy-modal-container:focus {
  outline-style: none;
}

.product-hierarchy-selection-container {
  display: flex;
  height: 70%;
}

.product-hierarchy-main-header {
  color: #333333;
  text-transform: capitalize !important;
}

.product-hierarchy-selection-label {
}

.disabled-section {
  pointer-events: none;
  opacity: 0.3;
}

.store-details-card-section {
  margin: 1rem;
}

.store-details-card-header {
  font-size: 16px;
  font-weight: bold;
}

.address-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-details-card-address {
  font-size: 12px;
  color: #707070;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10rem;
}

.store-details-bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-details-icon-section {
  text-align: center;
  margin: 1rem;
}

.store-details-icon {
  padding: 0.2rem !important;
}

.store-details-icon-label {
  font-size: 14px;
  color: #707070;
}

.product-hierarchy-tree-container {
  overflow: hidden;
  width: 50%;
}

.product-hierarchy-tree {
  margin: 1rem;
  height: 65%;
  overflow-y: auto;
}

.product-hierarchy-select {
  margin: 1rem;
  height: 90%;
  overflow-y: auto;
}

.product-filter-container {
  border: 1px solid #f2755f;
  transform: translateX(0);
}

.filtered-product-line-item-section {
  margin: 1rem;
  border-bottom: 1px solid #dddddd;
  border-bottom-style: dashed;
  padding-bottom: 1rem;
}

.filtered-product-line-item-section:last-child {
  border: 0px;
  margin: 1rem;
  padding-bottom: 1rem;
}

.filtered-product-line-item {
  padding: 0.5rem 1rem;
}

.rct-checkbox > img[alt='checkedIcon'] + .rct-title {
  background-color: red !important;
}

.rct-node {
  padding: 0.5rem;
}

.rct-title {
  font-size: 14px !important;
  color: #707070 !important;
}

.rct-node-parent.rct-node-collapsed {
  border-bottom: 1px solid #dddddd;
  /*width: 50%;*/
  border-bottom-style: dashed;
}

.product-select .MuiChip-label {
  font-size: 12px !important;
}

.product-select .MuiAutocomplete-tag {
  max-width: calc(70% - 6px) !important;
}
.popup-no-header {
  height: 3rem;
  background-color: #f9f9f9;
}
.popup-no-header-checkout {
  height: 2rem;
}
@media screen and (max-width: 600px) {
  .modal-container {
    min-width: 100%;
    min-height: 20rem;
    background-color: #ffffff;
    height: 50%;
  }
  .table-collections-group {
    border-spacing: 20px 0px;
    border-collapse: separate;
    margin-right: auto;
    margin-left: auto;
    table-layout: fixed;
    height: 100%;
  }
  .popup-close-section {
    position: absolute;
    right: 0;
    cursor: pointer;
  }
  .popup-no-header {
    height: 3rem !important;
  }
  .store-details-card-header {
    font-size: 14px;
    font-weight: bold;
  }

  .store-details-card-address {
    font-size: 11px;
  }

  .store-details-icon-section {
    text-align: center;
    margin: 0.3rem;
  }

  .store-details-icon-label {
    font-size: 11px;
  }

  .store-details-icon-img {
    height: 25px;
    width: 25px;
  }

  .product-card-container-2x2 {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr !important;
    grid-column-gap: 20px !important;
    grid-row-gap: 20px !important;
  }

  .product-card-container-4x4 {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr !important;
    grid-column-gap: 20px !important;
    grid-row-gap: 20px !important;
    height: 90%;
  }
}

.product-card-container-2x2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  height: 100%;
  margin: 1rem;
}

.product-card-container-4x4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.center-align {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 80%;*/
}

@media screen and (min-width: 1600px) {
  .choose-products-hierarchy-modal-container {
    width: 60%;
  }
}

.product-select .MuiAutocomplete-option {
  font-size: 14px;
  color: #707070;
}

.choose-products-section {
  display: flex;
  justify-content: center;
}

.selected-products-label {
  margin: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 14px;
  color: #767676;
}

.selected-products-label-highlight {
  margin: 0px 5px;
  color: #333333;
}
.products-label-warning {
  margin: 4px 5px;
  color: red;
  font-size: 12px;
}

.collection-title-container {
  position: absolute;
  font-size: 24px;
  margin: 0.4rem;
  display: none;
}

.collection-title {
  font-family: Brush Script MT;
  font-size: 24px;
  font-style: italic;
}

.product-selection-tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-selection-section {
  margin: 2rem 0.5rem;
}

.custom-tab-button {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #e6e6e6;
  cursor: pointer;
  font-size: 14px;
  color: #bbbbbb;
}

.custom-tab-button.active {
  border-bottom: 2px solid #f2755f;
  background-color: #ffffff;
  color: #777777;
}

.store-details-icon-label-cart {
  font-size: 14px;
  color: #707070;
  font-weight: bold;
}
