.map-container {
  padding: 1rem;
}

.store-location-map-container div {
  height: 100%;
}

.map-container-border {
  border: 2px solid #dedede;
}

.store-address-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
}

.store-pagination {
  font-size: 12px;
  margin: 5px 0px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .store-address-list-container {
    margin: 0rem;
  }
  .address-card {
    width: 12rem !important;
    height: 6rem !important;
  }
}

.address-card {
  width: 14rem;
  height: 8rem;
  text-align: center;
  border: 1px solid #f2755f !important;
}
