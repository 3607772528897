@import '../../../../../styles/colors';

#card {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 0.5rem;
  .cardContent {
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .label {
      color: $normal-text;
    }

    .value {
      margin-top: 0.25rem;
      font-weight: bold;
      color: $header-text;
    }
  }
}
