.container {
  /* height: 170px; */
  max-height: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: -2px;
  color: black;
  background-color: #ffffff;
}

.title {
  margin-right: -2px;
  /* to hide overlapped margin */
  height: 170px;
  width: 240px;
  color: #757575;
  border: 2px solid #e3e3e3;
  padding: 10px;
  overflow: visible;
}

.customInput {
  background-color: transparent;
  padding: 10px;
  color: black;
  font-size: 16px;
  border: 2px solid #e3e3e3;
  outline: none;
  width: 400px;
  align-self: stretch;
  resize: None;
}
.container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
}
